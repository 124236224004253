import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The PayPerks team has been growing! We are honored when the people who we want to join our team—people who could get any job they want—chose to come work with us and to help build our company.`}</p>
    <p>{`So cue the drum roll and let me introduce... (in order of start date):`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.linkedin.com/in/jessicamanley"
        }}>{`Jessica Manley`}</a>{` joins us from US Trust where she was working in Client Development and Sales. Among other talents, we were impressed with Jessica’s ability to get inside the head of the un- and underbanked consumer. At least weekly, she comes into the office with a story about someone she met on the street and how PayPerks can help improve the quality of their life. Jessica is tasked with being our user advocate and ensuring our education is relevant and actionable for all our users.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.linkedin.com/pub/ali-ahsan/5/843/720"
        }}>{`Ali Ahsan`}</a>{` wanted so badly to work at PayPerks he moved here all the way from Lahore, Pakistan to join the team. Ali has been exploring his new home with his wife and their new son. We quickly learned that Ali has many admirable traits, including: 1) he eats Subway sandwiches 5-7 times a week; 2) he can build Ikea furniture without the instructions; and 3) he is a scarily quick and adept problem solver. At PayPerks, he’s putting all those skills to good use as a core member of our software engineering team. Before PayPerks, he was at Strategic Systems International and Mantaq Systems, and received his Masters from Carnegie Mellon.`}</li>
      <li parentName="ul">{`Last but not least, `}<a parentName="li" {...{
          "href": "http://www.linkedin.com/in/chribar"
        }}>{`Caroline Hribar`}</a>{` has just joined team PayPerks as Director of Partner Development. Caroline and I met back in business school where she taught me everything I know about getting sh`}{`*`}{`t done, active listening and pretty much life in general. She is a master project manager, strategist, athlete and rainmaker – not to mention friend. She joins us from Deloitte Consulting.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      